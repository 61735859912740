// ******************************************************  TYPE  ****************************************************** //
export type FontVar = keyof typeof fontVariables;

// ******************************************************  FUNCTIONS  ****************************************************** //
export function getFont(f: FontVar) {
  return `var(${fontVariables[f]})`;
}

// ******************************************************  VARIABLES  ****************************************************** //
const fontVariables = {
  mainFont: '--main-font',
  headingFont: '--heading-font',
};
