import { CheckboxClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

/* ******************************************************  Override  ****************************************************** */
export const CustomCheckbox: Partial<OverridesStyleRules<CheckboxClassKey>> | undefined = {
  root: {
    '&.MuiCheckbox-root': {
      height: '24px',
      width: '24px',
      padding: '0px',
      backgroundColor: getColor('card'),
    },
  },
};
