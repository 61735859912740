import { appSetup } from '../../../index';
import { axiosData } from '../../axios-data';
import { EditSepaMandateRequestDto } from './edit-sepa-mandate-request-dto';
import { SepaMandateResponseDto } from './sepa-mandate-response-dto';
import { GetChangeLinkResponseDto } from './get-change-link-response-dto';

const getMandateChangeLinkInfo = (changeLinkUuid: string) => {
  const url = `/public/change-link/${changeLinkUuid}`;
  return axiosData(() => appSetup.getApplicationApi().get<GetChangeLinkResponseDto>(url))();
};

const editMandate = (mandateId: string, iban: string, accountHolder: string) => {
  const url = `/public/mandate/${mandateId}`;
  const data: EditSepaMandateRequestDto = {
    iban,
    accountHolder,
  };
  return axiosData(() => appSetup.getApplicationApi().put<SepaMandateResponseDto>(url, data))();
};

export const applicationService = {
  getMandateChangeLinkInfo,
  editMandate,
};
