import { CardClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomCard: Partial<OverridesStyleRules<CardClassKey>> | undefined = {
  root: {
    backgroundColor: getColor('card'),
    borderRadius: 0,
    color: getColor('cardContrast'),
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1)',

    '&.mandateChange': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '1232px',
    },
  },
};
