import React, { useEffect, useCallback, useState } from 'react';
import './App.scss';
import { getColor, setThemeColor } from './core/theme/colors/colors';
import { ThemeProvider } from '@mui/material';
import { muiTheme } from './core/theme/mui-theme/mui-theme';
import { ErrorSnackbar } from './core/components/shared/error-snackbar/ErrorSnackbar';
import { Loader } from './core/components/shared/loader/Loader';
import { Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from './core/components/landing-page/LandingPage';
import styled from '@emotion/styled';
import PageNotFound from './core/components/404/PageNotFound';
import UuidNotFound from './core/components/uuid-not-found/UuidNotFound';
import { useTranslation } from 'react-i18next';
import { LOCALES, MANDATE_CHANGE_LINK_STATUS, MandateUtil } from './util/mandate.util';
import { loaderService } from './core/services/loader.service';
import { lastValueFrom } from 'rxjs';
import { mandateRepository } from './data/mandate-repository';
import { GetChangeLinkResponse } from './data/get-change-link-response';
import { errorService } from './core/services/error.service';

const App = () => {
  const [mandateChangeLinkInfo, setMandateChangeLinkInfo] = useState<
    GetChangeLinkResponse | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { i18n } = useTranslation();

  const { pathname } = useLocation();

  useEffect(() => {
    setThemeColor();
  }, []);

  const getMandateChangeLinkInfo = useCallback(async (uuid: string) => {
    loaderService.showLoader();
    setIsLoading(true);
    await lastValueFrom(mandateRepository.getMandateChangeLinkInfo(uuid))
      .then((changeMandateLinkInfoResponse: GetChangeLinkResponse) => {
        setMandateChangeLinkInfo(changeMandateLinkInfoResponse);
        const locale = changeMandateLinkInfoResponse.locale;
        // Set application language
        setLanguage(locale);

        setTimeout(() => {
          loaderService.closeLoader();
          setIsLoading(false);
        }, 3000);
      })
      .catch((error) => {
        loaderService.closeLoader();
        setIsLoading(false);
        errorService.showError('mandateChange.errorRetrievingMandateChangeLinkInfo');
      });
  }, []);

  // Get uuid from the URL and retrieve mandate change link data
  useEffect(() => {
    if (pathname) {
      const uuid = MandateUtil.getUuid(pathname);

      if (uuid) {
        getMandateChangeLinkInfo(uuid);
      }
    }
  }, [pathname]);

  const setLanguage = (locale: string) => {
    switch (locale) {
      case LOCALES.it_IT:
        i18n.changeLanguage(LOCALES.it_IT);
        break;
      default:
        i18n.changeLanguage(LOCALES.en_GB);
    }
    console.log('Language set:', i18n.language);
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <ErrorSnackbar />
      <Loader />

      <Main className={isLoading ? 'hide' : ''}>
        <Routes>
          <Route path="/" element={<UuidNotFound />} />

          <Route path="/uuid" element={<UuidNotFound />} />

          <Route
            path="/uuid/:uuid"
            element={<LandingPage mandateChangeLinkInfo={mandateChangeLinkInfo} />}
          />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Main>
    </ThemeProvider>
  );
};

export default App;

const Main = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${getColor('background')};

  &.hide {
    opacity: 0;
  }
`;
