import MandateChange from './mandate-change/MandateChange';
import styled from '@emotion/styled';
import { GetChangeLinkResponse } from '../../../data/get-change-link-response';
import MandateChangeLinkError from '../mandate-change-link-error/MandateChangeLinkError';
import { MANDATE_CHANGE_LINK_STATUS } from '../../../util/mandate.util';

const LandingPage = ({
  mandateChangeLinkInfo,
}: {
  mandateChangeLinkInfo?: GetChangeLinkResponse;
}) => {
  const { status, mandateUuid } = { ...mandateChangeLinkInfo };

  return (
    <PageContainer>
      {status === MANDATE_CHANGE_LINK_STATUS.VALID ? (
        <MandateChange mandateUuid={mandateUuid} />
      ) : (
        <MandateChangeLinkError status={status} />
      )}
    </PageContainer>
  );
};

export default LandingPage;

const PageContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 30px 16px;

  @media screen and (min-width: 745px) {
    padding: 50px 104px;
  }
`;
