const ibanLengths: { [key: string]: number } = {
  AD: 24, // Andorra
  AE: 23, // United Arab Emirates
  AL: 28, // Albania
  AO: 25, // Angola
  AT: 20, // Austria
  AZ: 28, // Azerbaijan
  BA: 20, // Bosnia and Herzegovina
  BE: 16, // Belgium
  BF: 27, // Burkina Faso
  BG: 22, // Bulgaria
  BH: 22, // Bahrain
  BI: 16, // Burundi
  BJ: 28, // Benin
  BR: 29, // Brazil
  CH: 21, // Switzerland
  CI: 28, // Ivory Coast
  CM: 27, // Cameroon
  CR: 22, // Costa Rica
  CV: 25, // Cape Verde
  CY: 28, // Cyprus
  CZ: 24, // Czech Republic
  DE: 22, // Germany
  DK: 18, // Denmark
  DO: 28, // Dominican Republic
  DZ: 24, // Algeria
  EE: 20, // Estonia
  EG: 29, // Egypt
  ES: 24, // Spain
  FI: 18, // Finland
  FO: 18, // Faroe Islands
  FR: 27, // France
  GA: 27, // Gabon
  GB: 22, // United Kingdom
  GE: 22, // Georgia
  GI: 23, // Gibraltar
  GL: 18, // Greenland
  GN: 28, // Guinea
  GR: 27, // Greece
  GT: 28, // Guatemala
  HR: 21, // Croatia
  HU: 28, // Hungary
  IE: 22, // Ireland
  IL: 23, // Israel
  IR: 26, // Iran
  IS: 26, // Iceland
  IT: 27, // Italy
  JO: 30, // Jordan
  KW: 30, // Kuwait
  KZ: 20, // Kazakhstan
  LB: 28, // Lebanon
  LC: 32, // Saint Lucia
  LI: 21, // Liechtenstein
  LT: 20, // Lithuania
  LU: 20, // Luxembourg
  LV: 21, // Latvia
  LY: 25, // Libya
  MA: 28, // Morocco
  MC: 27, // Monaco
  MD: 24, // Moldova
  ME: 22, // Montenegro
  MG: 27, // Madagascar
  MK: 19, // North Macedonia
  ML: 28, // Mali
  MR: 27, // Mauritania
  MT: 31, // Malta
  MU: 30, // Mauritius
  MZ: 25, // Mozambique
  NL: 18, // Netherlands
  NO: 15, // Norway
  PK: 24, // Pakistan
  PL: 28, // Poland
  PS: 29, // Palestinian territories
  PT: 25, // Portugal
  QA: 29, // Qatar
  RO: 24, // Romania
  RS: 22, // Serbia
  SA: 24, // Saudi Arabia
  SC: 31, // Seychelles
  SE: 24, // Sweden
  SI: 19, // Slovenia
  SK: 24, // Slovakia
  SM: 27, // San Marino
  SN: 28, // Senegal
  ST: 25, // Sao Tome and Principe
  SV: 28, // El Salvador
  TL: 23, // East Timor
  TN: 24, // Tunisia
  TR: 26, // Turkey
  UA: 29, // Ukraine
  VG: 24, // British Virgin Islands
  XK: 20, // Kosovo
  YE: 30, // Yemen
  ZA: 24, // South Africa
};

export const isIBANLenghtCorrect = (iban: string | undefined) => {
  if (!iban) {
    return false;
  }
  // Remove any spaces in the IBAN
  iban = iban.replace(/\s+/g, '');

  // Get country
  const countryCode = iban.slice(0, 2);

  // Check if the country does exist in the list of countries - if not skip the validation
  if (!ibanLengths[countryCode]) {
    return true;
  }

  // Check that the IBAN is the correct length for the country code
  return ibanLengths[countryCode] === iban.length;
};

export const isChecksumCorrect = (iban: string | undefined) => {
  if (!iban) {
    return false;
  }

  // Remove any spaces in the IBAN
  iban = iban.replace(/\s+/g, '');

  // Capitalize IBAN letters
  iban = iban.toUpperCase();

  // Get country
  const countryCode = iban.slice(0, 2);

  // Check if the country does exist in the list of countries - if not IBAN is not valid
  if (!ibanLengths[countryCode]) {
    return false;
  }

  // Move the first four characters to the end of the string
  iban = iban.slice(4) + iban.slice(0, 4);

  // Replace each letter in the IBAN with two digits, using A=10, B=11, ..., Z=35
  const numericIBAN = iban
    .split('')
    .map((char) => {
      const code = char.charCodeAt(0);
      if (code >= 65 && code <= 90) {
        return code - 55;
      } else {
        return char;
      }
    })
    .join('');

  // Calculate the remainder when the numeric IBAN is divided by 97
  const remainder = BigInt(numericIBAN) % BigInt(97);

  // The IBAN is valid if the remainder is 1
  return remainder === BigInt(1);
};

export const isValidAccountHolderName = (name: string | undefined) => {
	const regex =
		/^[\p{L}]+([\u{0020}\u{002D}\u{058A}\u{0F0B}\u{2010}\u{30A0}\u{30FB}\u{1806}\u{2011}\u{2043}\u{2E17}\u{2E1A}\u{FE63}\u{FF0D}\u{0027}\u{002E}\u{003A}\u{005F}\u{00B7}\u{0387}\u{05F3}\u{05F4}\u{2018}\u{2019}\u{2024}\u{2027}\u{FE52}\u{FE55}\u{FF07}\u{FF0E}\u{FF1A}\u{FF3F}\u{0027}\u{002E}\u{003A}\u{0387}\u{2018}\u{2024}\u{FE52}\u{FE55}\u{FF07}\u{FF0E}\u{FF1A}\u{002C}]+[\p{L}]+[ .]*)*[0-9]*$/gu;

	if (!name) return false;

	return regex.test(name);
};

export const isAccountHolderNameLenghtCorrect = (name: string | undefined) => {
	if (!name) return false;

	const isCorrectLength = name.length >= 1 && name.length <= 70;

	return isCorrectLength;
};
