import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Axios from 'axios-observable';

interface Config {
  baseUrl: string;
  appOrigin: string;
  gppLibraryUrl: string;
}

export const appSetup = (() => {
  console.log('Starting...');

  let axiosInstance: Axios;
  let environment: Config;

  const AppWithCallbackAfterRender = () => {
    useEffect(() => {
      console.log('Started.');
    });

    return (
      <BrowserRouter>
        <App />
      </BrowserRouter>
    );
  };

  const init = (): Promise<unknown> => {
    return fetch('/config.json', { cache: 'no-store' })
      .then((response) => response.json())
      .then((config: Config) => {
        if (config) {
          // Populate environment object
          environment = config;

          // Instantiate axios
          axiosInstance = Axios.create({ baseURL: environment.baseUrl });

          // Render React element into DOM
          const container = document.getElementById('root');
          const root = ReactDOM.createRoot(container as HTMLElement);
          root.render(<AppWithCallbackAfterRender />);
        } else {
          console.log('Error retrieving configuration.');
        }
      });
  };
  return {
    start(): Promise<unknown> {
      return init();
    },
    getConfig(): Config {
      return environment;
    },
    getApplicationApi(): Axios {
      return axiosInstance;
    },
  };
})();

appSetup.start().catch((error) => console.log(error));

// const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
