import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { applicationService } from '../api/services/application/application.service';
import { SepaMandateResponse } from './sepa-mandate-response';
import { SepaMandateResponseDto } from '../api/services/application/sepa-mandate-response-dto';
import { GetChangeLinkResponse } from './get-change-link-response';
import { GetChangeLinkResponseDto } from '../api/services/application/get-change-link-response-dto';

const toGetChangeLinkResponse = (response: GetChangeLinkResponseDto): GetChangeLinkResponse => {
  return {
    url: response.url,
    mandateUuid: response.mandateUuid,
    uuid: response.uuid,
    status: response.status,
    dateCreated: response.dateCreated,
    merchantReference: response.merchantReference,
    consumerReference: response.consumerReference,
    creditor: response.creditor,
    locale: response.locale,
    ibanPrefixes: response.ibanPrefixes,
  };
};

const getMandateChangeLinkInfo = (changeLinkUuid: string): Observable<GetChangeLinkResponse> => {
  return applicationService
    .getMandateChangeLinkInfo(changeLinkUuid)
    .pipe(map(toGetChangeLinkResponse));
};

const toSepaMandateResponse = (response: SepaMandateResponseDto): SepaMandateResponse => {
  return {
    uuid: response.uuid,
    iban: response.iban,
    sepaMandateReference: response.sepaMandateReference,
    creditor: response.creditor,
    accountHolder: response.accountHolder,
    signatureDate: response.signatureDate,
    signatureLocation: response.signatureLocation,
    mandateType: response.mandateType,
    mandateScheme: response.mandateScheme,
    status: response.status,
    dateCreated: response.dateCreated,
    dateCancelled: response.dateCancelled,
    dateUpdated: response.dateUpdated,
    lastUsageDate: response.lastUsageDate,
  };
};

const editMandate = (
  uuid: string,
  iban: string,
  accountHolder: string
): Observable<SepaMandateResponse> => {
  return applicationService.editMandate(uuid, iban, accountHolder).pipe(map(toSepaMandateResponse));
};

export const mandateRepository = {
  getMandateChangeLinkInfo,
  editMandate,
};
