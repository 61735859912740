import { Button, Card } from '@mui/material';
import { getColor } from '../../../theme/colors/colors';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { StyledMandateChange } from './MandateChange.styles';
import { loaderService } from '../../../services/loader.service';
import { EditSepaMandateRequest } from '../../../../data/edit-sepa-mandate-request';
import { firstValueFrom } from 'rxjs';
import { mandateRepository } from '../../../../data/mandate-repository';
import { SepaMandateResponse } from '../../../../data/sepa-mandate-response';
import { errorService } from '../../../services/error.service';
import { isAccountHolderNameLenghtCorrect, isChecksumCorrect, isIBANLenghtCorrect, isValidAccountHolderName } from '../../../../util/validation.utils';
import { useState } from 'react';

const MandateChange = ({ mandateUuid }: { mandateUuid?: string }) => {
  const [t] = useTranslation();
  const [iban, setIban] = useState('');

  const initialValues = {
    accountHolder: '',
    iban: '',
  };

  const validationSchema = Yup.object({
    accountHolder: Yup.string()
			.required(t('validationMessages.accountHolderNameRequired'))
			.test(
				'isValidNameLength',
				t('validationMessages.accountHolderInvalidLength'),
				isAccountHolderNameLenghtCorrect
			)
			.test(
				'isValidName',
				t('validationMessages.accountHolderInvalidName'),
				isValidAccountHolderName
			),
    iban: Yup.string()
      .required(t('validationMessages.ibanRequired'))
      .test('isValidIBANLength', t('validationMessages.invalidLength'), isIBANLenghtCorrect)
      .test('isValidIBAN', t('validationMessages.invalidSum'), isChecksumCorrect),
  });

  const updateMandate = async (
    mandateUuid: string,
    formData: EditSepaMandateRequest,
    resetForm: any
  ) => {
    loaderService.showLoader();

    await firstValueFrom(
      mandateRepository.editMandate(mandateUuid, formData.iban, formData.accountHolder)
    )
      .then((updatedMandate: SepaMandateResponse) => {
        // Send response to parent domain
        window.parent.postMessage(updatedMandate, '*');
        console.log('Updating mandate success:', updatedMandate);

        setTimeout(() => {
          loaderService.closeLoader();
          resetForm();
        }, 2000);
      })
      .catch((error) => {
        // Send error to parent domain
        window.parent.postMessage(error, '*');
        console.log('Updating mandate failure:', error);

        loaderService.closeLoader();
        errorService.showError('mandateChange.errorChangingMandate');
      });
  };

  const submitHandler = (values: any, { setSubmitting, resetForm }: any) => {
    setSubmitting(true);

    if (mandateUuid) {
      const formData: EditSepaMandateRequest = {
        accountHolder: values.accountHolder.trim(),
        iban: values.iban.replace(/ +/g, ''),
      };

      updateMandate(mandateUuid, formData, resetForm).then(() => {
        setSubmitting(false);
      });
    }
  };

  const formatIBAN = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Get the input value from the event, convert to uppercase and remove all non-alphanumeric characters
    const input = event.currentTarget.value.toUpperCase().replace(/[^\dA-Z]/g, '');

    // Add a space after every 4 characters in the input
    const formattedInput = input.replace(/(.{4})/g, '$1 ');

    // Set the formatted input value as the state of the component
    setIban(formattedInput.trim());

    // Get a reference to the input element
    const target = event.target;

    if (target) {
      // Get the current cursor position or set it to 0 if not available
      const selectionEnd = target.selectionEnd || 0;

      // Set a new position for the cursor
      let newPosition = selectionEnd;

      // Handle the case when the backspace key is pressed and the cursor is on an empty space
      if ((event.nativeEvent as InputEvent).inputType === 'deleteContentBackward') {
        if (formattedInput.charAt(selectionEnd - 1) === ' ') {
          // Remove the empty space from the input and move the cursor one position to the left
          target.value =
            formattedInput.substring(0, selectionEnd - 2) +
            formattedInput.substring(selectionEnd - 1);
          newPosition -= 1;
        }
      } else {
        // If the cursor is on an empty space, move it one position to the right
        if (formattedInput.charAt(selectionEnd - 1) === ' ') {
          newPosition += 1;
        }
      }

      // Set the new cursor position using a setTimeout to ensure the input value has been updated
      setTimeout(() => {
        target.setSelectionRange(newPosition, newPosition);
      }, 0);
    }
  };

  return (
    <Card className="mandateChange">
      <StyledMandateChange.CardHeader>
        {t('mandateChange.cardTitle')}
      </StyledMandateChange.CardHeader>

      <StyledMandateChange.CardBody>
        <StyledMandateChange.FormContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) =>
              submitHandler(values, { setSubmitting, resetForm })
            }
          >
            {({ touched, values, errors, handleChange }) => (
              <Form>
                <StyledMandateChange.FormFieldContainer>
                  <Field name="accountHolder" id="accountHolder" type="text">
                    {({ field }: any) => {
                      return (
                        <StyledMandateChange.InputContainer>
                          <StyledMandateChange.StyledInput
                            {...field}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChange(e);
                            }}
                            placeholder={t('mandateChange.namePlaceholder')}
                            color={
                              touched.accountHolder && errors.accountHolder
                                ? getColor('error')
                                : getColor('cancelButtonBorder')
                            }
                          />
                          {(!touched.accountHolder ||
												    (touched.accountHolder && !errors.accountHolder)) && (
                            <StyledMandateChange.StyledLabel
                              color={
                                !values.accountHolder && touched.accountHolder
                                  ? getColor('error')
                                  : getColor('inputLabel')
                              }
                            >
                              {t('mandateChange.accountHolder')}
                            </StyledMandateChange.StyledLabel>
                          )}
                          <StyledMandateChange.StyledErrorMessage>
                            {touched.accountHolder && <ErrorMessage name="accountHolder" />}
                          </StyledMandateChange.StyledErrorMessage>
                        </StyledMandateChange.InputContainer>
                      );
                    }}
                  </Field>

                  <Field name="iban" id="iban" type="text">
                    {({ field }: any) => {
                      return (
                        <StyledMandateChange.InputContainer>
                          <StyledMandateChange.StyledInput
                            {...field}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChange(e);
                              formatIBAN(e);
                            }}
                            value={iban}
                            placeholder={t('mandateChange.ibanPlaceholder')}
                            color={
                              touched.iban && errors.iban
                                ? getColor('error')
                                : getColor('cancelButtonBorder')
                            }
                          />
                          {(!touched.iban || (touched.iban && !errors.iban)) && (
                            <StyledMandateChange.StyledLabel
                              color={
                                !values.iban && touched.iban
                                  ? getColor('error')
                                  : getColor('inputLabel')
                              }
                            >
                              {t('mandateChange.iban')}
                            </StyledMandateChange.StyledLabel>
                          )}
                          <StyledMandateChange.StyledErrorMessage>
                            {touched.iban && <ErrorMessage name="iban" />}
                          </StyledMandateChange.StyledErrorMessage>
                        </StyledMandateChange.InputContainer>
                      );
                    }}
                  </Field>
                </StyledMandateChange.FormFieldContainer>

                <StyledMandateChange.TextContainer>
                  {t('mandateChange.authorizationText')}
                </StyledMandateChange.TextContainer>
                <StyledMandateChange.TextContainer>
                  {t('mandateChange.paymentTypeText')}
                </StyledMandateChange.TextContainer>

                <Field name="submitButton" id="submitButton" type="text">
                  {({ form }: FieldProps) => (
                    <StyledMandateChange.ButtonContainer>
                      <Button
                        className="primary-color-button"
                        type="submit"
                        disabled={form.isSubmitting || !(form.isValid && form.dirty)}
                        variant="contained"
                        id="sepa-mandate-change-confirm-button"
                      >
                        {t('mandateChange.confirm')}
                      </Button>
                    </StyledMandateChange.ButtonContainer>
                  )}
                </Field>
              </Form>
            )}
          </Formik>
        </StyledMandateChange.FormContainer>
      </StyledMandateChange.CardBody>
    </Card>
  );
};

export default MandateChange;
