export const lightTheme = {
  primary: '#0078d6',
  primaryContrast: '#ffffff',

  secondary: '#ffffff',
  secondaryContrast: '#333333',

  background: '#f8f8f8',
  backgroundContrast: '#767676',

  splash: '#000000',
  splashContrast: '#f8f8f8',

  card: '#ffffff',
  cardContrast: '#333333',
  cardBorder: '#e8e8e8',

  cardHeader: '#f8f8f8',
  cardHeaderContrast: '#333333',

  input: '#f8f8f8',
  inputContrast: '#696969',

  checkboxLabel: '#333333',

  heading: '#333333',

  success: '#1d861d',
  successContrast: '#FFFFFF',

  error: '#d92121',
  errorContrast: '#FFFFFF',

  info: '#0078d6',
  infoContrast: '#FFFFFF',

  helperText: '#9f9f9f',
  cancelButtonBorder: '#bbbbbb',
  inputLabel: '#767676',
};
