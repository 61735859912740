import { DialogClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomDialog: Partial<OverridesStyleRules<DialogClassKey>> | undefined = {
  root: {
    '&.loaderDialog': {
      // must be higher than default
      zIndex: 1301,

      '& .MuiBackdrop-root': {
        backgroundColor: getColor('splash'),
      },

      '& .MuiDialog-container': {
        paddingBottom: '1vh',
      },

      '& .MuiDialog-paper': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
  },
};
