import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';
import { getFont } from '../../../theme/fonts/fonts';

const CardHeader = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${getColor('cardHeaderContrast')};
  background-color: ${getColor('cardHeader')};
  height: 49px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  border-bottom: 1px solid ${getColor('cardBorder')};

  @media screen and (min-width: 745px) {
    font-size: 16px;
    line-height: 24px;
    padding: 9px 33px 16px;
    letter-spacing: 0.3px;
  }
`;

const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px;

  @media screen and (min-width: 745px) {
    padding: 50px 30px;
  }
`;

const FormContainer = styled.div`
  width: 309px;

  @media screen and (min-width: 745px) {
    width: 465px;
  }
`;

const FormFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media screen and (min-width: 745px) {
    gap: 16px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
`;

const StyledInput = styled.input((props) => ({
  height: '56px',
  width: '100%',
  backgroundColor: getColor('input'),
  border: `1px solid ${props.color}`,
  fontFamily: getFont('mainFont'),
  fontSize: '16px',
  lineHeight: '24px',
  color: getColor('inputContrast'),
  padding: '16px',

  '&::placeholder': {
    fontFamily: getFont('mainFont'),
    fontSize: '16px',
    fontWeight: 400,
    color: getColor('inputContrast'),
    lineHeight: '24px',
  },
}));

const StyledLabel = styled.label((props) => ({
  display: 'block',
  fontFamily: getFont('mainFont'),
  fontSize: '12px',
  lineHeight: '16px',
  color: props.color,
  paddingLeft: '16px',
  marginTop: '4px',
}));

const StyledErrorMessage = styled.div`
  color: ${getColor('error')};
  font-family: ${getFont('mainFont')};
  font-size: 12px;
  padding-left: 16px;
  margin-top: 4px;
`;

const TextContainer = styled.div`
  font-family: ${getFont('mainFont')};
  font-size: 14px;
  font-weight: 400;
  color: ${getColor('helperText')};
  line-height: 20px;
  width: 100%;
  margin-top: 33px;

  @media screen and (min-width: 745px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 48px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 75px;

  button {
    width: 242px;
  }

  @media screen and (min-width: 745px) {
    margin-top: 48px;
    justify-content: flex-start;

    button {
      width: 200px;
    }
  }
`;

export const StyledMandateChange = {
  CardHeader,
  CardBody,
  FormContainer,
  FormFieldContainer,
  InputContainer,
  StyledInput,
  StyledLabel,
  StyledErrorMessage,
  TextContainer,
  ButtonContainer,
};
