import { ButtonBaseClassKey, ButtonClassKey, IconButtonClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor, getDarkerColor } from '../../colors/colors';
import { getFont } from '../../fonts/fonts';

export const CustomButton: Partial<OverridesStyleRules<ButtonClassKey>> | undefined = {
  root: {
    letterSpacing: '0.4px',
    boxShadow: 'none',
    fontFamily: getFont('mainFont'),

    '&:hover': {
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
    },

    '&.primary-color-button': {
      backgroundColor: getColor('primary'),
      color: getColor('primaryContrast'),
      borderColor: getColor('primary'),
      borderWidth: '1px',
      borderStyle: 'solid',
      padding: '15px 32px',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '24px',
      textTransform: 'none',
      borderRadius: '2px',

      '&:hover': {
        backgroundColor: getDarkerColor('primary', 16),
        borderColor: getDarkerColor('primary', 16),
      },

      '&:active': {
        backgroundColor: getDarkerColor('primary', 8),
        borderColor: getDarkerColor('primary', 8),
      },

      '&:disabled': {
        opacity: 0.4,
        cursor: 'not-allowed !important',
      },
    },

    '&.cancel-button': {
      backgroundColor: getColor('secondary'),
      color: getColor('secondaryContrast'),
      borderColor: getColor('cancelButtonBorder'),
      borderWidth: '1px',
      borderStyle: 'solid',
      padding: '15px 32px',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '24px',
      textTransform: 'none',
      borderRadius: '2px',

      '&:hover': {
        backgroundColor: getDarkerColor('secondary', 12),
      },

      '&:active': {
        backgroundColor: getDarkerColor('secondary', 6),
      },
    },
  },
};

export const CustomIconButton: Partial<OverridesStyleRules<IconButtonClassKey>> | undefined = {
  root: {
    color: '#333333',
  },
};

export const CustomButtonBase: Partial<OverridesStyleRules<ButtonBaseClassKey>> | undefined = {
  root: {
    backgroundColor: '#ffffff',

    '&.mpay-checkbox': {
      color: '#333333',
      backgroundColor: 'transparent',

      '&.Mui-checked': {
        backgroundColor: 'transparent',
      },
    },
  },
};
