import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_EN_GB from './core/locales/en-gb/translation';
import translation_IT_IT from './core/locales/it-it/translation';

const resources = {
  en_GB: {
    translation: translation_EN_GB,
  },
  it_IT: {
    translation: translation_IT_IT,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en_GB', // use en_GB if detected lng is not available
    keySeparator: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
