import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { getColor } from '../../../theme/colors/colors';
import { getFont } from '../../../theme/fonts/fonts';
import ErrorIcon from '../../../../assets/images/error.svg';
import InfoIcon from '../../../../assets/images/info.svg';
import { ICON_TYPE } from '../../../../util/mandate.util';

const ErrorBanner = ({
  title,
  description,
  iconType,
  id,
}: {
  title: string;
  description: string;
  iconType: ICON_TYPE;
  id?: string;
}) => {
  const [t] = useTranslation();

  return (
    <ErrorContainer
      id={id}
      color={iconType === ICON_TYPE.ERROR ? getColor('error') : getColor('info')}
    >
      <IconAndTitleContainer>
        <Circle
          className="icon"
          color={iconType === ICON_TYPE.ERROR ? getColor('error') : getColor('info')}
          itemProp={iconType === ICON_TYPE.ERROR ? 'error' : 'info'}
        >
          {iconType === ICON_TYPE.ERROR ? (
            <img src={ErrorIcon} alt="Error Icon" />
          ) : (
            <img src={InfoIcon} alt="Info Icon" />
          )}
        </Circle>

        <ErrorTitle>{t(title)}</ErrorTitle>
      </IconAndTitleContainer>
      <ErrorDescription>{t(description)}</ErrorDescription>
    </ErrorContainer>
  );
};

const Circle = styled.div((props) => ({
  borderRadius: '50%',
  backgroundColor: props.color,
  height: '54px',
  width: '54px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& img': {
    height: props.itemProp === 'error' ? '12px' : '16px',
    width: props.itemProp === 'error' ? '12px' : '16px',
  },

  '@media screen and (min-width: 745px)': {
    height: '64px',
    width: '64px',

    '& img': {
      height: props.itemProp === 'error' ? '14px' : '18px',
      width: props.itemProp === 'error' ? '14px' : '18px',
    },
  },
}));

const ErrorContainer = styled.div((props) => ({
  width: '100%',
  maxWidth: '1018px',
  backgroundColor: '#e8e8e8',
  color: '#333333',
  border: `2px solid ${props.color}`,
  borderRadius: '5px',
  padding: '24px 16px 60px',

  '@media screen and (min-width: 745px)': {
    padding: '40px 24px 24px',
  },
}));

const IconAndTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 70px;

  & .icon {
    flex: 0 0 54px;

    @media screen and (min-width: 745px) {
      flex: 0 0 64px;
    }
  }

  @media screen and (min-width: 745px) {
    margin-bottom: 0;
  }
`;

const ErrorTitle = styled.div`
  font-size: 34px;
  font-weight: 400;
  color: ${getColor('heading')};
  line-height: 44px;
  font-family: ${getFont('headingFont')};
  margin-left: 24px;
`;

const ErrorDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  font-family: ${getFont('mainFont')};

  @media screen and (min-width: 745px) {
    padding-left: 88px;
  }
`;

export default ErrorBanner;
