import React from 'react';
import styled from '@emotion/styled';
import { MANDATE_CHANGE_LINK_STATUS } from '../../../util/mandate.util';
import ErrorBanner from '../shared/error-banner/ErrorBanner';
import { ICON_TYPE } from '../../../util/mandate.util';

const MandateChangeLinkError = ({ status }: { status?: string }) => {
  return (
    <Container>
      {status === MANDATE_CHANGE_LINK_STATUS.EXPIRED ? (
        <ErrorBanner
          title={'mandateChangeLinkError.expired'}
          description={'mandateChangeLinkError.expiredDesc'}
          id="mandate-change-link-error-expired"
          iconType={ICON_TYPE.INFO}
        />
      ) : status === MANDATE_CHANGE_LINK_STATUS.CONSUMED ? (
        <ErrorBanner
          title={'mandateChangeLinkError.consumed'}
          description={'mandateChangeLinkError.consumedDesc'}
          id="mandate-change-link-error-consumed"
          iconType={ICON_TYPE.INFO}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default MandateChangeLinkError;
