import React from 'react';
import styled from '@emotion/styled';
import { getFont } from '../../theme/fonts/fonts';
import { useTranslation } from 'react-i18next';

const UuidNotFound = () => {
  const [t] = useTranslation();

  return (
    <PageContainer>
      <InnerContainer>
        <ErrorTitle>{t('uuidNotFound.title')}</ErrorTitle>
        <ErrorMessage>{t('uuidNotFound.messagePart1')}</ErrorMessage>
        <ErrorMessage>{t('uuidNotFound.messagePart2')}</ErrorMessage>
        <ErrorMessage>{t('uuidNotFound.messagePart3')}</ErrorMessage>
      </InnerContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 4rem;
`;

const InnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ErrorTitle = styled.div`
  color: #ffffff;
  text-shadow: #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px,
    #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px;
  font-size: 3rem;
  font-family: ${getFont('mainFont')};
  font-weight: bold;
  margin-bottom: 4rem;
  text-transform: uppercase;

  @media only screen and (min-width: 620px) {
    font-size: 5rem;
  }

  @media only screen and (min-width: 745px) {
    font-size: 8rem;
  }

  @media only screen and (min-width: 990px) {
    font-size: 12rem;
  }

  @media only screen and (min-width: 1262px) {
    font-size: 16rem;
  }
`;

const ErrorMessage = styled.div`
  color: #000000;
  font-size: 1.4rem;
  font-family: ${getFont('mainFont')};

  @media only screen and (min-width: 620px) {
    font-size: 1.8rem;
  }

  @media only screen and (min-width: 745px) {
    font-size: 2.2rem;
  }

  @media only screen and (min-width: 990px) {
    font-size: 3rem;
  }

  @media only screen and (min-width: 1262px) {
    font-size: 3.8rem;
  }
`;

export default UuidNotFound;
