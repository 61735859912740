import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorService } from '../../../services/error.service';
import styled from '@emotion/styled';
import { getColor, getDarkerColor } from '../../../theme/colors/colors';
import { Button, Snackbar } from '@mui/material';

export const ErrorSnackbar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const [t] = useTranslation();

  const closeHandler = useCallback((event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  }, []);

  useEffect(() => {
    const sub = errorService.error.subscribe((errorCode: string) => {
      setIsOpen(true);
      setMessage(errorCode);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [t]);

  return (
    <StyledErrorSnackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={closeHandler}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      color="warn"
    >
      <Content>
        <Message>{t(message)}</Message>
        <OkButton onClick={closeHandler}>
          <h4>OK</h4>
        </OkButton>
      </Content>
    </StyledErrorSnackbar>
  );
};

const StyledErrorSnackbar = styled(Snackbar)`
  background: ${getColor('error')};
  border-radius: 0.8rem;
  max-width: 50rem;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  color: ${getColor('errorContrast')};
  padding: 1.5rem 3rem;
  font-weight: 400;
  font-size: 1.4rem;
`;

const OkButton = styled(Button)`
  height: 6.4rem;
  background-color: ${getColor('error')};
  color: ${getColor('errorContrast')} !important;

  &:hover {
    background-color: ${getDarkerColor('error', 2)};
  }
`;
