import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { getFont } from '../../theme/fonts/fonts';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MandateUtil } from '../../../util/mandate.util';

const PageNotFound = () => {
  const [counter, setCounter] = useState(5);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [t] = useTranslation();

  // useEffect(() => {
  //   let timer: any;
  //   if (counter > 0) {
  //     timer = setTimeout(() => {
  //       const newValue = counter - 1;
  //       setCounter(newValue);
  //     }, 1000);
  //   } else {
  //     backToHome();
  //   }
  //
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [counter]);

  const backToHome = () => {
    if (pathname) {
      const uuid = MandateUtil.getUuid(pathname);
      navigate(`/uuid/${uuid}`);
    } else {
      // UuidNotFound component
      navigate('/');
    }
  };

  return (
    <PageContainer>
      <InnerContainer>
        <Message>404</Message>
        <MessageSmall>{t('pageNotFound.title')}</MessageSmall>
        <Button
          className="primary-color-button back-home-button"
          onClick={() => backToHome()}
          type="button"
          variant="contained"
          id="not-found-back-home-button"
        >
          <span>{t('pageNotFound.backHomeIn')}</span>&nbsp;
          <span>{counter}</span>
        </Button>
      </InnerContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 4rem;
`;

const InnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Message = styled.div`
  color: #ffffff;
  text-shadow: #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px,
    #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px, #000 0 0 1px;
  font-size: 12rem;
  font-family: ${getFont('mainFont')};
  font-weight: bold;

  //@media only screen and (min-width: 745px) {
  //  font-size: 16rem;
  //}
`;

const MessageSmall = styled.div`
  color: #000000;
  font-size: 2.4rem;
  font-family: ${getFont('mainFont')};
  margin-bottom: 5rem;
  //
  //@media only screen and (min-width: 745px) {
  //  font-size: 3.8rem;
  //}
`;

export default PageNotFound;
