import { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { loaderService } from '../../../services/loader.service';
import LogoIcon from '../../../../assets/images/logo-star.svg';
import styled from '@emotion/styled';
import { Spinner } from './spinner/Spinner';

export const Loader = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const sub = loaderService.loader.subscribe((showLoader: boolean) => {
      setIsOpen(showLoader);
    });
    return () => {
      sub.unsubscribe();
    };
  });

  const closeHandler = (event: any, reason?: string) => {
    // reason: Can be: "escapeKeyDown", "backdropClick"
    if (reason) {
      return;
    }

    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} className="loaderDialog" onClose={closeHandler}>
      <SpacerHelper />
      <StyledImage src={LogoIcon} alt="Mercedes-Benz Logo Icon" />
      <Spinner />
    </Dialog>
  );
};

const SpacerHelper = styled.div`
  height: 198px;

  @media only screen and (min-width: 745px) {
    height: 264px;
  }
`;

const StyledImage = styled.img`
  height: 150px;
  user-select: none;

  @media only screen and (min-width: 745px) {
    height: 220px;
  }
`;
